import React from 'react';
import styled from 'styled-components';
import InstagramLogo from '@assets/images/instagram.svg';
import VimeoLogo from '@assets/images/vimeo.svg';

const Container = styled.footer`
  section {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    margin-top: 0px;
    padding: ${({ home }) => (home ? '40px 0' : '0')};

    @media (max-width: 600px) {
      display: block;
    }

    a,
    p {
      display: block;
      margin-bottom: 15px;
      text-decoration: none;
      color: #807f80;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      img {
        width: 23px;
        display: block;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  h3 {
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    margin-bottom: 30px;
  }
`;

const SocialContainer = styled.div`
  width: 30%;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Social = styled.ul`
  display: flex;
  margin-top: 30px;

  li {
    a {
      background-color: #bfbfbf;
      border-radius: 5px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      l img {
        display: block;
      }
    }
    &:last-child {
      margin-left: 8px;
    }
  }
`;

const Contact = styled.div`
  width: 70%;
  padding-right: 40px;

  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 40px;
  }

  p {
    line-height: 1.3;
  }
  /* a {
    display: block;
    margin-bottom: 15px;
    text-decoration: none;
  } */
`;

const Hide = styled.div`
  display: ${({ home }) => (home ? 'flex' : 'none')};

  @media (max-width: 600px) {
    display: ${({ home }) => (home ? 'block' : 'none')};
  }
`;

const Copyright = styled.p`
  border-top: solid 1px #807f80;
  padding: 40px 0;
  font-size: 14px;
  color: #807f80;
  margin-top: ${({ home }) => (home ? '0' : '30px')};
`;

export default function Footer({ home }) {
  return (
    <Container home={home}>
      <section>
        <Hide home={home}>
          <Contact>
            <h3>About us</h3>
            <p>
              Lookout is a creative company that makes films. From the initial
              concept, through the various phases of production, all the way to
              launch, our collaborative method pairs an ambitious creative team
              with resourceful producers to create work that is both inspired
              and effective.
            </p>
            <a href="mailto:info@lookoutcontent.com">info@lookoutcontent.com</a>
            <a href="tel:416-526-4592">416.526.4592</a>
          </Contact>

          <SocialContainer>
            <h3>Follow Us</h3>
            <p>Stay in touch with us</p>
            <Social>
              <li>
                <a href="https://vimeo.com/lookoutcontent">
                  <img src={VimeoLogo} alt="Vimeo" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/lookoutcontent/">
                  <img src={InstagramLogo} alt="Instagram" />
                </a>
              </li>
            </Social>
          </SocialContainer>
        </Hide>
      </section>
      <Copyright
        home={home}
      >{`©${new Date().getFullYear()} Lookout Content`}</Copyright>
    </Container>
  );
}
