import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  position: relative;
`;

export default ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};
