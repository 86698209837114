import React from 'react';
import { Link } from 'gatsby';
import Logo from '@assets/images/lookout-black.png';
import LogoHealth from '@assets/images/lookout-health-logo.png';

import styled from 'styled-components';

const HeaderStyled = styled.header`
  display: ${({ isLandingPage }) => (isLandingPage ? 'none' : 'flex')};
  justify-content: ${({ home }) => (home ? 'space-between' : 'center')};
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  flex-direction: column;
  position: ${({ isProject }) => (isProject ? 'static' : 'absolute')};
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;

  @media (max-width: 600px) {
    margin-bottom: 30px;
    position: static;
    display: ${({ isHealthHomepage, isLandingPage }) =>
      isHealthHomepage || isLandingPage ? 'none' : 'flex'};
  }
`;

const LogoStyled = styled.h1`
  margin-bottom: 20px;
  a {
    display: block;
  }

  img {
    display: block;
    width: 270px;
    position: relative;
    top: 1px;

    @media (max-width: 600px) {
      width: 200px;
    }
  }

  @media (max-width: 600px) {
    margin-bottom: 0;
  }

  @media (min-width: 600px) {
    display: ${({ isHealthHomepage }) => (isHealthHomepage ? 'none' : 'flex')};
  }
`;

export default function Header({ location, isHealthProject, isProject }) {
  const isHealthHomepage = location?.pathname.includes('health');
  const logo = isHealthHomepage || isHealthProject ? LogoHealth : Logo;

  const landingPage =
    location?.pathname.includes('animation') ||
    location?.pathname.includes('live-action');

  console.log(landingPage);

  return (
    <HeaderStyled
      home={location?.pathname === '/'}
      isProject={isProject}
      isHealthHomepage={isHealthHomepage}
      isLandingPage={landingPage}
    >
      {!isHealthHomepage && (
        <LogoStyled
          home={location?.pathname === '/'}
          isHealthHomepage={isHealthHomepage}
        >
          <Link to={isHealthProject ? '/health' : '/'}>
            <img src={logo} alt="Lookout" />
          </Link>
        </LogoStyled>
      )}
    </HeaderStyled>
  );
}
