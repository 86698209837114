import React from 'react';
import { createGlobalStyle } from 'styled-components';
import Header from '@components/header';
import Footer from '@components/footer';
import Wrapper from '@components/wrapper';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export const GlobalStyle = createGlobalStyle`
  :root {
    --colour-background: ${({ home }) => (home ? '#272528' : '#FFF')};
    --colour-text: ${({ home }) => (home ? '#FFF' : '#000')};


    --colour-nav: #08df9d;
    --font-family: "Gotham";
    --font-weight-regular: 400;
    --font-weight-medium: 400;
    --font-weight-bold: 900;
    --font-weight-black: 900;
    --breakpoint-1: "600px";
  }
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
  }
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
      display: block;
  }
  body {
      line-height: 1;
  }
  ol, ul {
      list-style: none;
  }
  blockquote, q {
      quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
      content: '';
      content: none;
  }
  table {
      border-collapse: collapse;
      border-spacing: 0;
  }

  html {
    background-color: var(--colour-background);
  }
  body {
    font-family: var(--font-family);
    background-color: var(--colour-background);
    color: var(--colour-text);
    font-weight: var(--font-weight-regular);
    visibility: hidden;

    .wf-active & {
      visibility: visible;
    }
  }
  a {
    color: var(--colour-text);
  }

 
`;

export default function Layout({
  children,
  path,
  location,
  isHealthProject,
  isProject,
}) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            url
          }
        }
      }
    `
  );

  return (
    <React.Fragment>
      <GlobalStyle home={location?.pathname === '/'} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{siteMetadata.title}</title>
        <link rel="canonical" href={siteMetadata.url} />
      </Helmet>
      <Wrapper>
        <Header
          location={location}
          isHealthProject={isHealthProject}
          isProject={isProject}
        />
      </Wrapper>
      {children}
      <Wrapper>
        <Footer home={true} />
      </Wrapper>
    </React.Fragment>
  );
}
